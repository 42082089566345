import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { StoresStorage } from '@services/settings';

@Injectable()
export class StoresGuard implements CanActivate {
  constructor(private storesStorage: StoresStorage) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.storesStorage.initAllStores().then(() => true);
  }
}
