import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { GRAPH_HEIGHT, GRAPH_HEIGHT_STRING_SIZE } from '@constants/graph-setting';
import { ParamsService } from '@core/service/params.service';

@Injectable({
  providedIn: 'root',
})
export class GraphSettingsService {
  readonly showLegendParam: string = 'showLegend';
  readonly showLabelParam: string = 'showLabel';
  readonly heightGraphParam: string = 'heightGraph';

  showLegend$ = new BehaviorSubject<boolean>(this.paramsService.getParamForBoolean(this.showLegendParam, false));
  showLabel$ = new BehaviorSubject<boolean>(this.paramsService.getParamForBoolean(this.showLabelParam, false));
  heightGraph$ = new BehaviorSubject<string>(this.paramsService.getParamsForString(this.heightGraphParam) || GRAPH_HEIGHT.MIDDLE);

  constructor(private paramsService: ParamsService) {}

  getGraphSettings() {
    return {
      onShowLegend: () => this.toggleLegend(),
      onShowLabel: () => this.toggleLabel(),
      onSetHeightGraph: (height: string) => this.setHeightGraph(height),
      onDownload: () => this.downloadGraph(),
    };
  }

  toggleLegend() {
    const showLegend: boolean = !this.showLegend$.getValue();
    this.showLegend$.next(showLegend);
    this.paramsService.setParams([{ name: this.showLegendParam, value: showLegend }], true);
  }

  toggleLabel() {
    const showLabel: boolean = !this.showLabel$.getValue();
    this.showLabel$.next(showLabel);
    this.paramsService.setParams([{ name: this.showLabelParam, value: showLabel }], true);
  }

  getHeightGraph(): number {
    return Number(GRAPH_HEIGHT_STRING_SIZE[this.heightGraph$.getValue()].slice(0, 3));
  }

  setHeightGraph(height: string) {
    this.heightGraph$.next(height);
    this.paramsService.setParams([{ name: this.heightGraphParam, value: height }], true);
  }

  downloadGraph() {}
}
